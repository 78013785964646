define('talent-search/components/talent/talent-bio', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service('store'),
    mobile: Ember.computed('row', function () {
      if (this.get('row.talent.user.phone')) {
        return this.get('row.talent.user.country_code') + '' + this.get('row.talent.user.phone');
      } else {
        return '';
      }
    }),
    showPhoneError: false,
    init() {
      this._super(...arguments);
      this.get('store').queryRecord("notification", this.get('row.talent.user.id')).then(notification => {
        this.set('notification', notification);
      });
    },
    actions: {
      openModal(type) {
        if (this.get('row.talent.user.phone')) {
          this.set('conversation', null);
          this.set('messageFormOpened', !this.get('messageFormOpened'));

          var params = {
            participantId: this.get('row.talent.user.id'),
            type: type
          };
          this.get('store').unloadAll('conversation');
          this.get('store').createRecord("conversation", params).save().then(conversation => {
            this.set('conversation', conversation);
          });
          window.$('#kt_chat_modal').modal('show');
        } else {
          this.set('showPhoneError', true);
        }
      }
    }
  });
});