define('talent-search/components/common/actions-cell', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    showDeletePopup: Ember.computed('row', function () {
      // The delete button only will be available if column.deletePopup is true
      // In case of being a string, follows a specific logic for each table
      if (typeof this.get('column.deletePopup') == 'boolean') {
        return this.get('column.deletePopup');
      } else {
        switch (this.get('column.deletePopup')) {
          case 'user.id':
            return this.get('row.content.user.id') != this.get('column.currentUser.user.id');
          default:
            return false;
        }
      }
    }),
    actions: {
      deleteRow: function (row) {
        // Before deleting a row we render a popup with the deleted row data
        // If we confirm, we destroy the row and remove it from the table
        let deletedItem = row.get('content');
        window.swal({
          title: 'Are you sure?',
          text: `Once deleted, you will not be able to recover this ${this.get('column.deletePopupType')}`,
          type: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          reverseButtons: true
        }).then(result => {
          if (result.value) {
            window.swal({
              text: `${deletedItem.get(`${this.get('column.deletePopupTitle')}`)} has been deleted`,
              type: 'success'
            });
            deletedItem.destroyRecord();
            this.get('table').removeRow(deletedItem);
          }
        });
      }
    }
  });
});