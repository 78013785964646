define('talent-search/models/job-talent', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    talent: _emberData.default.attr(),
    in_introduction: _emberData.default.attr(),
    in_education: _emberData.default.attr(),
    in_experience: _emberData.default.attr(),
    in_skills: _emberData.default.attr(),
    in_title: _emberData.default.attr(),
    recent_company: _emberData.default.attr(),
    recent_job: _emberData.default.attr(),
    created_at: _emberData.default.attr(),
    answers: _emberData.default.attr()
  });
});