define('talent-search/routes/index', ['exports', 'talent-search/routes/base-route', 'talent-search/config/environment'], function (exports, _baseRoute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    pushNotification: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    beforeModel() {
      this._super(...arguments);
      if (!this.get('currentUser.session.data.authenticated.jobbio_admin')) {
        window.location.href = `https://${_environment.default.APP.DOMAIN}`;
      }
      this.get('pushNotification').register();
      this.transitionTo('job');
    }
  });
});