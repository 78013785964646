define('talent-search/components/common/main-header', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    header: Ember.computed('row', function () {
      if (this.get('model.dedicated_talent')) {
        return `Talent`;
      } else if (this.get('model.talent')) {
        return `${this.get('model.job.company.name')} <span style="color:#fa6160">|</span> ${this.get('model.job.title')} <span style="color:#fa6160">|</span> ${this.get('model.job.location.city')}`;
      } else if (this.get('model.index')) {
        return `Homepage`;
      } else if (this.get('model.job')) {
        return `Jobs`;
      }
    })
  });
});