define('talent-search/models/conversation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    initiator: _emberData.default.attr(),
    participants: _emberData.default.attr(),
    type: _emberData.default.attr(),

    name: _emberData.default.attr(),
    messages_no: _emberData.default.attr(),

    last_message: _emberData.default.attr(),
    last_message_at: _emberData.default.attr(),
    last_message_sender: _emberData.default.attr(),

    unread: _emberData.default.attr('number'),

    // this is not an actual attribute only used when creating
    // a conversation on our side, it is not returned by the API!!
    participantId: _emberData.default.attr('number'),
    participant: null,

    title() {
      var participants = this.get('participants');
      var title = "";
      // i na one to one convo we'll use the other persons ful lname
      participants.forEach(participant => {
        if (participant.role == "talent") {
          this.set('participant', participant);
          title = participant.first_name + " " + participant.last_name;
        }
      });
      return title;
    }
  });
});