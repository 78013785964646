define('talent-search/components/job/job-list', ['exports', 'talent-search/components/base-component', 'talent-search/components/common/simple-table', 'talent-search/config/environment', 'moment'], function (exports, _baseComponent, _simpleTable, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend(_simpleTable.default, {
    modelType: 'job',
    date_from: '',
    location_filter: '',
    band_filter: '',
    status_filter: '',
    package_filter: '',
    type_filter: '',
    date_to: (0, _moment.default)().format('YYYY-MM-DD'),
    exporting: false,
    visible: false,
    errors: null,
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['location'] = this.get('location_filter');
      params['band'] = this.get('band_filter');
      params['status'] = 'active';
      params['package'] = this.get('package_filter');
      params['type'] = this.get('type_filter');
      params['date_from'] = this.get('dateFromFilter');
      params['date_to'] = this.get('dateToFilter');
      return params;
    }),
    jobTypeOptions: Ember.computed('jobForm.job_types', function () {
      // We create an array of options for the Job Type select cells
      var jobTypeOptions = [];
      this.get('jobForm.job_types').forEach(type => {
        var jobType = {
          value: type.id,
          text: type.name
        };
        jobTypeOptions.push(jobType);
      });
      return jobTypeOptions;
    }),
    jobLevelOptions: Ember.computed('jobForm.job_levels', function () {
      // We create an array of options for the Job Level select cells
      var jobLevelOptions = [];
      this.get('jobForm.job_levels').forEach(level => {
        var jobLevel = {
          value: level,
          text: `${level.charAt(0).toUpperCase()}${level.slice(1)}`
        };
        jobLevelOptions.push(jobLevel);
      });
      return jobLevelOptions;
    }),
    columns: Ember.computed(function () {
      // label: name of the column (header)
      // valuePath: model property that we render inside the cell
      // format: if we want the cell value to be a computed property we can use a function here
      // cellComponent: component that we want to render inside our cell
      // cellClassNames: class names for the cells in this column
      // sortable: false (because we will sort rown by using the API)
      // align: text-align for both the header and the body of the table
      // draggable: lets the user drag and drop columns to reorder them
      // width: column width (for horizontal scrolling all columns must have a width)
      // disabled: user for checkbox cells
      // validate: used for input cells
      // deletePopup: shows the trash icon to delete a row
      // deletePopupType: the type of the row we will use in the deletePopup text
      // deletePopupTitle: the name of the row we will use in the deletePopup text
      // editPopup: shows the edit icon to edit a row and the id of the modal to be rendered
      // addPopup: shows the add icon to add things a row and the id of the modal to be rendered
      // hidden: hides the column if the value is true
      return [{
        label: 'View',
        cellComponent: 'common/actions-cell',
        deletePopupTitle: 'title',
        deletePopupType: 'job',
        editPopup: {
          show: true,
          id: 'edit-job'
        },
        addPopup: {
          show: false,
          id: ''
        },
        deletePopup: false,
        sortable: false,
        align: 'center',
        width: '7%'
      }, {
        label: 'Date Posted',
        format: function (valuePath) {
          return valuePath ? (0, _moment.default)(valuePath).fromNow() : 'Undefined';
        },
        valuePath: 'published_at',
        sortable: false,
        draggable: true,
        width: '13%',
        cellClassNames: 'fw-700'
      }, {
        label: 'Job Title',
        valuePath: 'title',
        sortable: false,
        draggable: true,
        width: '25%'
      }, {
        label: 'Company',
        valuePath: 'company.name',
        sortable: false,
        draggable: true
      }, {
        label: 'Applications',
        valuePath: 'applications',
        sortable: false,
        draggable: true,
        width: '12%'
      }, {
        label: 'Shortlisted',
        valuePath: 'shortlisted',
        sortable: false,
        draggable: true,
        width: '12%'
      }, {
        label: 'Views',
        valuePath: 'views',
        sortable: false,
        draggable: true,
        width: '12%'
      }];
    }),
    init() {
      this._super(...arguments);
      this.set('errors', {});
      this.set('dateToFilter', this.get('date_to'));
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.send('loadFilters');
    },
    actions: {
      loadFilters: function () {
        var self = this;
        var url = `${_environment.default.APP.API_HOST}/admin/jobs/filters`;
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'GET'
          }).done(function (data) {
            self.set('filters', data);
          });
        });
      },
      onLocationChanged: function (location) {
        this.set('location_filter', location);
      },
      onBandChanged: function (band) {
        this.set('band_filter', band);
      },
      onStatusChanged: function (status) {
        this.set('status_filter', status);
      },
      onPackageChanged: function (pack) {
        this.set('package_filter', pack);
      },
      onJobSelected: function (job) {
        this.set('selectedJob', job);
      },
      onTypeChanged: function (type) {
        this.set('type_filter', type);
      },
      setDateFilters: function (dateFrom, dateTo) {
        this.send('setDateFrom', dateFrom);
        this.send('setDateTo', dateTo);
      },
      setDateFrom: function (dateFrom) {
        // Set the date from for the job list and export
        if ((0, _moment.default)(dateFrom).isBefore(this.get('dateToFilter')) || !this.get('dateToFilter') || dateFrom == '' || (0, _moment.default)(dateFrom).isSame(this.get('dateToFilter'))) {
          this.set('dateFromFilter', dateFrom);
          this.set('errors.dateFrom', '');
        } else {
          this.set('errors.dateFrom', 'The Date From cannot be greater than the Date To');
        }
      },
      setDateTo: function (dateTo) {
        // Set the date to for the job list and export
        if ((0, _moment.default)(dateTo).isAfter(this.get('dateFromFilter')) || !this.get('dateFromFilter') || dateTo == '' || (0, _moment.default)(dateTo).isSame(this.get('dateFromFilter'))) {
          this.set('dateToFilter', dateTo);
          this.set('errors.dateTo', '');
        } else {
          this.set('errors.dateTo', 'The Date To cannot be lower than the Date From');
        }
      },
      selectRow: function (row) {
        this.set('selectedRow', row.content);
        //this.set('visible', true);
        //this.set('collapse_head', 'm-portlet--collapse');
        //this.set('collapse_body', 'display: none; overflow: hidden; padding-top: 0px; padding-bottom: 0px;');
        this.get('router').transitionTo('received-talent', this.get('selectedRow.company.slug'), this.get('selectedRow.slug'), { queryParams: { job_slug: this.get('selectedRow.slug'), company_slug: this.get('selectedRow.company.slug') } });
      }
    }
  });
});