define('talent-search/components/talent/application-chat-list', ['exports', 'talent-search/components/pagination-component'], function (exports, _paginationComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _paginationComponent.default.extend({
        modelType: 'message',
        conversation: null,
        messageText: '',
        lastMessage: null,
        autoLoad: false,
        didRender() {
            Ember.run.later(function () {
                window.$('.kt-chat .kt-scroll')[0].scrollTop = window.$('.kt-chat .kt-scroll')[0].scrollHeight;
            }, 100);
        },
        messages: Ember.computed('objects.lastObject', function () {
            if (this.get('objects')) {
                var messages = this.get('objects').sortBy('created_at');
                if (this.get('lastMessage')) {
                    messages.pushObject(this.get('lastMessage'));
                }
                return messages;
            } else {
                return [];
            }
        }),
        params: Ember.computed('cursor', 'conversation', function () {
            var params = this._super();
            if (params['cursor'] == undefined || params['cursor'] == 'undefined') {
                delete params['cursor'];
            }
            params['conversation_id'] = this.get('conversation.id');
            params['paginate'] = true;
            return params;
        }),
        onMessageFormOpened: Ember.observer('messageFormOpened', function () {
            this.set('messageText', '');
        }),
        didUpdateAttrs() {
            // clear out old messages
            this.set('objects', []);
            this.set('hasMore', true);
            this.set('loading', false);
            this.set('cursor', '');
            this.set('lastMessage', null);
            if (this.get('conversation') != null) {
                this.send('loadMore');
            }
        },
        actions: {
            sendMessage(messageText) {
                if (messageText) {
                    var conversation = this.get('conversation');
                    if (conversation == null) {
                        // we can't send the message if we don't have a conversation for it
                        alert('An unexpected error occurred, please try again later');
                        return;
                    }
                    // create a fake message to add to the chat
                    var messageParams = {
                        'is_sender': true,
                        'text': messageText,
                        'read': true,
                        'conversation_id': this.get('conversation.id')
                    };
                    var message = this.get('store').createRecord('message', messageParams);
                    message.save().then(record => {
                        this.set('lastMessage', record);
                        this.get('messages').pushObject(record);
                    });
                    this.set('messageText', '');
                }
            },
            loaded() {
                // reset the array to trigger reversing the messages
                let temp = this.get('objects').slice(0);
                this.set('objects', temp);
            },
            loadMoreMessage() {
                if (this.get('conversation') != null) {
                    this.send('loadMore');
                }
            }
        }
    });
});