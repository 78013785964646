define('talent-search/models/message', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        conversation_id: _emberData.default.attr(),
        is_sender: _emberData.default.attr(),
        read: _emberData.default.attr(),
        text: _emberData.default.attr(),
        is_auto_reply: _emberData.default.attr(),
        sender: _emberData.default.attr(),
        created_at: _emberData.default.attr()
    });
});