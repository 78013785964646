define('talent-search/components/common/progression-cell', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    color: Ember.computed('row', function () {
      return "bg-gray";
    })
  });
});