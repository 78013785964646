define('talent-search/routes/received-talent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel(transition) {
      this._super(...arguments);
      if (!transition.params["received-talent"].company_slug) {
        this.transitionTo('job');
      }
    },
    model: function (params) {
      return this.get('store').queryRecord('job', params).then(function (response) {
        return { job: response, talent: true };
      });
    }
  });
});