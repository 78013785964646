define('talent-search/components/common/checkbox-cell', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    checkboxValue: Ember.computed('row', function () {
      // Depending on the table we have to manually consider several checkbox values. Some of them even have different types
      return this.get('value') == 0 || this.get('value') == 'paused' || this.get('value') == 'frozen' || !this.get('value');
    }),
    checkboxDisabled: Ember.computed('row', function () {
      // Some checkboxes have to be disabled
      // We will check column.disabled and if it is not a boolean each value will follow a different logic
      if (typeof this.get('column.disabled') == 'boolean') {
        return this.get('column.disabled');
      } else {
        switch (this.get('column.disabled')) {
          case 'company.sponsored':
            return !this.get('row.company.sponsored');
          default:
            return false;
        }
      }
    }),
    actions: {
      updateCell: function () {
        // The cell parameter is set to the selected value
        this.set(`row.content.${this.get('column.valuePath')}`, this.get('value'));
        this.get('row.content').save();
      }
    }
  });
});