define("talent-search/models/application", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    job: _emberData.default.attr(),
    talent: _emberData.default.attr(),
    device: _emberData.default.attr(),
    status: _emberData.default.attr(),
    source: _emberData.default.attr(),
    created_at: _emberData.default.attr("date"),
    updated_at: _emberData.default.attr("date"),
    answers: _emberData.default.attr()
  });
});