define('talent-search/routes/dedicated-talent', ['exports', 'talent-search/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model(params) {
      return Ember.RSVP.hash({
        talent: this.get('store').queryRecord('talent', params.id),
        dedicated_talent: true
      });
    }
  });
});