define('talent-search/routes/talent', ['exports', 'talent-search/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    beforeModel(transition) {
      this._super(...arguments);
      if (!transition.queryParams.company_slug) {
        this.transitionTo('job');
      }
    },
    model: function (params) {
      return this.get('store').queryRecord('job', params).then(function (response) {
        return { job: response, talent: true };
      });
    }
  });
});