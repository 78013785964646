define("talent-search/models/job", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr("string"),
    slug: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    contract: _emberData.default.attr("string"),
    featured: _emberData.default.attr(),
    level: _emberData.default.attr("string"),
    city: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),
    address: _emberData.default.attr("string"),
    country_short: _emberData.default.attr("string"),
    latitude: _emberData.default.attr(),
    longitude: _emberData.default.attr(),
    post_code: _emberData.default.attr("string"),
    state: _emberData.default.attr("string"),
    content: _emberData.default.attr(),
    company: _emberData.default.attr(),
    type: _emberData.default.attr(),
    questions: _emberData.default.attr(),
    location: _emberData.default.attr(),
    order: _emberData.default.attr(),
    salary_from: _emberData.default.attr("number"),
    salary_to: _emberData.default.attr("number"),
    salary_disclosed: _emberData.default.attr("number"),
    status: _emberData.default.attr("string"),
    views: _emberData.default.attr("number"),
    impressions: _emberData.default.attr("number"),
    applications: _emberData.default.attr("number"),
    shortlisted: _emberData.default.attr("number"),
    passed: _emberData.default.attr("number"),
    band: _emberData.default.attr("number"),
    published_at: _emberData.default.attr("date"),
    updated_at: _emberData.default.attr("date"),
    created_at: _emberData.default.attr("date")
  });
});