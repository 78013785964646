define('talent-search/components/common/sidebar-nav', ['exports', 'talent-search/components/base-component', 'talent-search/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    router: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    cloudfront: _environment.default.APP.CLOUD_FRONT_HOST,
    logoDark: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/logo_dark.png");
    }),
    logoLight: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/logo_light.png");
    }),
    talent: Ember.computed('router.currentRouteName', function () {
      if (this.get('router.currentRouteName').includes('talent')) {
        return "kt-menu__item--active";
      }
    }),
    job: Ember.computed('router.currentRouteName', function () {
      if (this.get('router.currentRouteName').includes('job')) {
        return "kt-menu__item--active";
      }
    }),
    didRender() {
      this._super(...arguments);
    },
    actions: {
      logout: function () {
        var registration_id = '';
        var self = this;
        navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
          serviceWorkerRegistration.pushManager.getSubscription().then(function (pushSubscription) {
            var endpointParts = pushSubscription.endpoint.split('/');
            registration_id = endpointParts[endpointParts.length - 1];
            let url = `${_environment.default.APP.API_HOST}/user/device/wp/${registration_id}`;
            self.get('session').authorize('authorizer:token', (headerName, headerValue) => {
              window.$.ajax({
                url: url,
                headers: {
                  Authorization: headerValue
                },
                type: 'DELETE',
                contentType: 'application/json;charset=utf-8',
                dataType: 'json'
              }).then(() => {
                self.get('session').invalidate();
                window.location.href = `https://${_environment.default.APP.DOMAIN}`;
              });
            });
          });
        });
      }
    }
  });
});