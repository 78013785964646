define('talent-search/routes/job', ['exports', 'talent-search/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model() {
      return Ember.RSVP.hash({
        form: this.store.queryRecord('job-form', 1), // 1 is meaningless
        job: true
      });
    }
  });
});