define('talent-search/router', ['exports', 'talent-search/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('base-route');
    this.route('test');
    this.route('talent');
    this.route('job');
    this.route('received-talent', { path: '/received/talent/:company_slug/:job_slug' });
    this.route('discover-talent', { path: '/discover/talent/:company_slug/:job_slug' });
    this.route('search-talent', { path: '/search/talent/:company_slug/:job_slug' });
    this.route('dedicated-talent', { path: '/talent/:id' });
  });

  exports.default = Router;
});