define('talent-search/mixins/simple-table', ['exports', 'ember-light-table', 'ember-concurrency'], function (exports, _emberLightTable, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service('store'),
    autoLoad: false,
    limit: 20,
    isLoading: Ember.computed.oneWay('fetchRecords.isRunning'),
    canLoadMore: true,
    enableSync: true,
    rows: null,
    modelType: null,
    meta: null,
    columns: null,
    table: null,
    params: Ember.computed('searchTerm', 'cursor', 'limit', function () {
      // We use limit because is needed by ember-light-table
      return {
        search: this.get('searchTerm'),
        cursor: this.get('cursor'),
        page_size: this.get('limit')
      };
    }),
    paramsChanged: Ember.observer('searchTerm', 'status_filter', 'jobStatusFilter', 'status', 'dateFromFilter', 'dateToFilter', 'job_location_filter', 'job_level_filter', 'package_filter', 'location_filter', 'band_filter', 'source_filter', 'type_filter', function () {
      // If any of these parameters changes we will reset the params and create a new table
      this.get('resetSearch').perform();
      this.send('createTable');
    }),
    init() {
      this._super(...arguments);
      this.set('rows', []);
      this.send('createTable');
    },
    resetSearch: (0, _emberConcurrency.task)(function* () {
      // Just like the active-pagination-component resetSearch task
      // We empty the rows
      this.set('rows', []);
      this.set('cursor', '');
      // Wait while user is typing
      yield (0, _emberConcurrency.timeout)(400);
      this.set('canLoadMore', true);
      this.get('fetchRecords').perform();
    }).restartable(),
    fetchRecords: (0, _emberConcurrency.task)(function* () {
      // Just like the pagination-component loadMore action
      yield (0, _emberConcurrency.timeout)(700);
      yield this.get('store').query(this.get('modelType'), this.get('params')).then(response => {
        var self = this;
        // Avoid adding the same rows if the cursor is not updated
        if (this.get('cursor') != response.get('meta.next') || this.get('cursor') == undefined) {
          response.forEach(function (object) {
            self.get('rows').pushObject(object);
          });
          this.set('meta', response.get('meta'));
          this.set('cursor', this.get('meta.next'));
          if (this.get('cursor') == null) {
            this.set('canLoadMore', false);
          }
        }
      });
    }).restartable(),
    actions: {
      onScrolledToBottom() {
        // When we scroll to the bottom of the table we load more rows if available
        if (this.get('canLoadMore')) {
          this.get('fetchRecords').perform();
        }
      },
      fetchModalResults(tableID) {
        // Custom function to fecth more records when we scroll to the bottom of a table in a modal
        var rect = window.$(`#${tableID} tbody tr:last`);
        var top_of_element = rect.offset().top;
        var bottom_of_element = rect.offset().top + rect.outerHeight();
        var bottom_of_screen = window.$(window).scrollTop() + window.$(window).innerHeight();
        var top_of_screen = window.$(window).scrollTop();

        if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
          this.send('onScrolledToBottom');
        }
      },
      createTable() {
        // We create the table
        let table = new _emberLightTable.default(this.get('columns'), this.get('rows'), {
          enableSync: true
        });
        this.set('table', table);
      }
    }
  });
});