define('talent-search/components/common/status-cell', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    class: Ember.computed('row', function () {
      if (this.get('value') == 'pending') {
        return "kt-badge--warning";
      } else if (this.get('value') == 'submitted') {
        return "kt-badge--success";
      } else if (this.get('value') == 'shortlisted') {
        return "kt-badge--success";
      } else if (this.get('value') == 'passed') {
        return "kt-badge--danger";
      } else if (this.get('value') == 'draft') {
        return "kt-badge--warning";
      } else if (this.get('value') == true) {
        return "kt-badge--success";
      } else if (this.get('value') == false) {
        return "kt-badge--gray";
      }
    }),
    text: Ember.computed('row', function () {
      if (this.get('value') == 'pending') {
        return "Pending";
      } else if (this.get('value') == 'submitted') {
        return "Submitted";
      } else if (this.get('value') == 'shortlisted') {
        return "Shortlisted";
      } else if (this.get('value') == 'passed') {
        return "Passed";
      } else if (this.get('value') == 'draft') {
        return "Draft";
      } else if (this.get('value') == true) {
        return "Yes";
      } else if (this.get('value') == false) {
        return "No";
      }
    })
  });
});