define('talent-search/components/talent/application-chat-list-item', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    // marks unread messages as read
    didRender() {
      this._super(...arguments);
      if (this.get('message.read') == false) {
        this.set('message.read', true);
        this.get('message').save();
      }
    }
  });
});