define('talent-search/components/talent/application-chat', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseComponent.default.extend({
        title: Ember.computed('conversation', function () {
            if (this.get('conversation') == null) {
                return "";
            }
            return this.get('conversation').title();
        })
    });
});