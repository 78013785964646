define('talent-search/components/talent/talent-list-discover', ['exports', 'talent-search/components/base-component', 'talent-search/mixins/simple-table', 'moment'], function (exports, _baseComponent, _simpleTable, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend(_simpleTable.default, {
    modelType: 'job-talent',
    date_from: '',
    status_filter: '',
    date_to: (0, _moment.default)().format('YYYY-MM-DD'),
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['date_from'] = this.get('dateFromFilter');
      params['date_to'] = this.get('dateToFilter');
      params['job'] = this.get('model.job.id');
      params['status'] = this.get('status_filter');
      return params;
    }),
    columns: Ember.computed(function () {
      // label: name of the column (header)
      // valuePath: model property that we render inside the cell
      // format: if we want the cell value to be a computed property we can use a function here
      // cellComponent: component that we want to render inside our cell
      // cellClassNames: class names for the cells in this column
      // sortable: false (because we will sort rown by using the API)
      // align: text-align for both the header and the body of the table
      // draggable: lets the user drag and drop columns to reorder them
      // width: column width (for horizontal scrolling all columns must have a width)
      // disabled: user for checkbox cells
      // validate: used for input cells
      // deletePopup: shows the trash icon to delete a row
      // deletePopupType: the type of the row we will use in the deletePopup text
      // deletePopupTitle: the name of the row we will use in the deletePopup text
      // editPopup: shows the edit icon to edit a row and the id of the modal to be rendered
      // addPopup: shows the add icon to add things a row and the id of the modal to be rendered
      // hidden: hides the column if the value is true

      return [{
        label: 'ID',
        valuePath: 'talent.user.id',
        sortable: false,
        align: 'center',
        draggable: true,
        width: '6.5%'
      }, {
        label: 'Applied',
        format: function (valuePath) {
          return (0, _moment.default)(valuePath).format('LL');
        },
        valuePath: 'created_at',
        sortable: false,
        draggable: true,
        cellClassNames: 'fw-700'
      }, {
        label: 'First Name',
        valuePath: 'talent.user.first_name',
        sortable: false,
        draggable: true
      }, {
        label: 'Last Name',
        valuePath: 'talent.user.last_name',
        sortable: false,
        draggable: true
      }, {
        label: 'Job',
        valuePath: 'recent_job',
        sortable: false,
        draggable: true,
        width: '18%'
      }, {
        label: 'Company',
        valuePath: 'recent_company',
        sortable: false,
        draggable: true,
        width: '11%'
      }, {
        label: 'Bio%',
        cellComponent: 'common/progression-cell',
        valuePath: 'talent.completion',
        sortable: false,
        draggable: true,
        width: '10%'
      }, {
        label: 'Last Edit',
        format: function (valuePath) {
          return (0, _moment.default)(valuePath).fromNow();
        },
        valuePath: 'talent.updated_at',
        sortable: false,
        draggable: true,
        width: '11%'
      }, {
        label: 'Last Seen',
        format: function (valuePath) {
          return valuePath ? (0, _moment.default)(valuePath).fromNow() : 'Undefined';
        },
        valuePath: 'talent.user.last_login',
        sortable: false,
        draggable: true,
        width: '12%'
      }];
    }),
    init() {
      this._super(...arguments);
      this.set('errors', {});
      this.set('dateToFilter', this.get('date_to'));
    },
    actions: {
      setDateFilters: function (dateFrom, dateTo) {
        this.send('setDateFrom', dateFrom);
        this.send('setDateTo', dateTo);
      },
      setDateFrom: function (dateFrom) {
        // Set the date from for the talent list and export
        if ((0, _moment.default)(dateFrom).isBefore(this.get('dateToFilter')) || !this.get('dateToFilter') || dateFrom == '' || (0, _moment.default)(dateFrom).isSame(this.get('dateToFilter'))) {
          this.set('dateFromFilter', dateFrom);
          this.set('errors.dateFrom', '');
        } else {
          this.set('errors.dateFrom', 'The Date From cannot be greater than the Date To');
        }
      },
      setDateTo: function (dateTo) {
        // Set the date to for the talent list and export
        if ((0, _moment.default)(dateTo).isAfter(this.get('dateFromFilter')) || !this.get('dateFromFilter') || dateTo == '' || (0, _moment.default)(dateTo).isSame(this.get('dateFromFilter'))) {
          this.set('dateToFilter', dateTo);
          this.set('errors.dateTo', '');
        } else {
          this.set('errors.dateTo', 'The Date To cannot be lower than the Date From');
        }
      },
      rowClicked: function () {
        window.$('#kt_chat_modal').modal('hide');
      },
      onStatusChanged: function (status) {
        this.set('status_filter', status);
      }
    }
  });
});