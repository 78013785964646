define('talent-search/adapters/application', ['exports', 'talent-search/adapters/drf', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _drf, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_dataAdapterMixin.default, {
    addTrailingSlashes: false,
    authorizer: 'authorizer:token',
    // there are certain queries we'll want to intercept
    // so that we can change the url while still using
    // the meber store
    query: function (store, type) {
      switch (type.modelName) {
        case 'talent':
          return this.getTalent(...arguments);
        case 'application':
          return this.getJobApplication(...arguments);
        case 'job-type':
          return this.getJobTypes(...arguments);
        case 'job-talent':
          return this.getJobTalent(...arguments);
        case 'message':
          return this.getMessage(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    // same as query but for single record
    queryRecord: function (store, type) {
      switch (type.modelName) {
        case 'job-form':
          return this.jobFormQuery(...arguments);
        case 'job':
          return this.jobSlugQuery(...arguments);
        case 'job-stat':
          return this.getJobStats(...arguments);
        case 'talent':
          return this.loadTalent(...arguments);
        case 'notification':
          return this.loadNotification(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    createRecord: function (store, type) {
      switch (type.modelName) {
        case 'conversation':
          return this.addConversation(...arguments);
        case 'message':
          return this.addMessage(...arguments);
        case 'push-notification':
          return this.registerWebPush(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    // same as above but for patching a single record
    updateRecord: function (store, type) {
      switch (type.modelName) {
        case 'message':
          return this.updateMessage(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    getData(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      return data;
    },
    getTalent(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/admin/talent";
      return this.ajax(url, 'GET', { data: query });
    },
    getJobTalent(store, type, query) {
      var job = query.job;
      var url = this.get('host') + this.get('namespace') + "/admin/jobs/" + job + "/talent";
      return this.ajax(url, 'GET', { data: query });
    },
    getMessage(store, type, query) {
      var conversation_id = query.conversation_id;
      var url = this.get('host') + this.get('namespace') + "/conversations/" + conversation_id + "/messages";
      return this.ajax(url, 'GET', { data: query });
    },
    getJobStats(store, type, query) {
      var job = query;
      var url = this.get('host') + this.get('namespace') + "/admin/jobs/" + job + "/talent/stats";
      return this.ajax(url, 'GET', { data: query }).then(response => {
        var d = new Date();
        var id = d.getTime();
        response.id = id;
        return response;
      });
    },
    loadTalent(store, type, id) {
      var url = this.get('host') + this.get('namespace') + "/admin/talent/" + id;
      return this.ajax(url, 'GET').then(response => {
        response.id = response.user.id;
        return response;
      });
    },
    jobFormQuery(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/jobs/form";
      return this.ajax(url, 'GET', { data: query });
    },
    getJobApplication(store, type, query) {
      var job = query.job;
      var url = this.get('host') + this.get('namespace') + "/jobs/" + job + "/applications";
      return this.ajax(url, 'GET', { data: query });
    },
    staffFormQuery(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/admin/form";
      return this.ajax(url, 'GET', { data: query });
    },
    getJobTypes(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/jobs/jobtypes";
      return this.ajax(url, 'GET', { data: query });
    },
    jobSlugQuery(store, type, query) {
      var company_slug = query.company_slug;
      var job_slug = query.job_slug;
      var url = this.get('host') + this.get('namespace') + "/jobs/" + company_slug + "/" + job_slug;
      return this.ajax(url, 'GET', { data: query });
    },
    addConversation() {
      var data = this.getData(...arguments);
      data['participants'] = [data.participant_id];
      data['text'] = data.last_message;
      data['type'] = data.type;
      var url = this.get('host') + this.get('namespace') + "/conversations";
      return this.ajax(url, 'POST', { data: data });
    },
    addMessage() {
      var data = this.getData(...arguments);
      var conversation_id = data.conversation_id;
      var url = this.get('host') + this.get('namespace') + "/conversations/" + conversation_id + "/messages";
      return this.ajax(url, 'POST', { data: data });
    },
    registerWebPush() {
      var data = this.getData(...arguments);
      if (data.browser == "NETSCAPE") {
        data.browser = "CHROME";
      }
      var url = this.get('host') + this.get('namespace') + "/user/device/wp";
      return this.ajax(url, 'POST', { data: data });
    },
    loadNotification(store, type, id) {
      var url = this.get('host') + this.get('namespace') + "/admin/talent/" + id + "/messages";
      return this.ajax(url, 'GET').then(response => {
        response.id = response.talent;
        return response;
      });
    },
    updateMessage(store, type, snapshot) {
      var data = this.getData(...arguments);
      var conversation_id = data.conversation_id;
      var message_id = snapshot.id;

      // only want to send over read as being the changed property
      // if we sent the text property api would assume we want to patch it
      delete data.text;

      var url = this.get('host') + this.get('namespace') + "/conversations/" + conversation_id + "/messages/" + message_id;
      var res = this.ajax(url, 'PATCH', { data: data }).then().catch(() => {
        return null;
      });
      return res;
    }
  });
});