define('talent-search/components/job/job-stat', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    init() {
      this._super(...arguments);
      var job = this.get('model.job.id');
      this.get('store').queryRecord('job-stat', job).then(response => {
        this.set('object', response);
      });
    }
  });
});