define('talent-search/components/base-component', ['exports', 'talent-search/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service('session'),
    cloudfront: _environment.default.APP.CLOUD_FRONT_HOST
  });
});