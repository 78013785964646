define('talent-search/components/common/rounded-logo', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    image: null,
    initials: Ember.computed('name', function () {
      var name = this.get('name');
      var initials = name ? name.split(" ").map(n => n[0]).join("") : '';
      initials = initials.substring(0, 3);
      return initials;
    })
  });
});