define('talent-search/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    load() {
      let userId = this.get('session.data.authenticated.jobbio_admin.user.id');
      if (!Ember.isEmpty(userId)) {
        return this.get('store').findRecord('staff', userId).then(response => {
          this.set('staff', response);
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });
});