define('talent-search/models/push-notification', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    auth: _emberData.default.attr(),
    browser: _emberData.default.attr(),
    name: _emberData.default.attr(),
    p256dh: _emberData.default.attr(),
    registration_id: _emberData.default.attr()
  });
});