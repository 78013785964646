define('talent-search/controllers/talent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: {
      'job_slug': { refreshModel: true },
      'company_slug': { refreshModel: true }
    }
  });
});