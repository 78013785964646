define('talent-search/components/common/select-cell', ['exports', 'talent-search/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    saving: false,
    actions: {
      updateCell: function (selectedValue) {
        // The cell parameter is set to the selected value while we render the saving gif
        this.set('saving', true);
        this.set(`row.content.${this.get('column.valuePath')}`, selectedValue);
        this.get('row.content').save().then(() => {
          this.set('saving', false);
        });
      }
    }
  });
});